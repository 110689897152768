<template>
  <b-card>
    <div class="d-flex">
      <h3 class="mb-0 color4" style="font-weight: 700">UPLOAD FILE</h3>
    </div>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <b-form class="mt-1" @submit.prevent="handleSubmit(onSubmit)">
          <b-row>
            <b-col cols="12" md="12">
              <validation-provider
                #default="validationContext"
                name="file_date"
                rules="required"
              >
                <b-form-group
                  label="Date"
                  :label-for="`file_date`"
                  label-cols-md="3"
                  class="required"
                >
                  <flat-pickr
                    v-model="documentData.file_date"
                    class="form-control"
                    :config="flatDateConfig"
                    placeholder="DD-MM-YY"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="12">
              <b-form-group
                label="Document Category"
                label-for="document_category_id"
                label-cols-md="3"
              >
                <div class="form-col-select">
                  <v-select
                    id="document_category_id"
                    v-model="documentData.document_category_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="documentCategoryOptions"
                    :clearable="true"
                    label="category_name"
                    :reduce="(option) => option.id"
                    placeholder="Select Document category"
                  />

                  <feather-icon size="18" icon="ChevronDownIcon" />
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="12" v-if="!isClientFilter">
              <b-form-group
                label="Document Type"
                label-for="document_type_id"
                label-cols-md="3"
              >
                <div class="form-col-select">
                  <v-select
                    id="document_type_id"
                    v-model="documentData.document_type_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="documentTypeOptions"
                    :clearable="true"
                    label="type"
                    :reduce="(option) => option.id"
                    placeholder="Select Document type"
                  />
                  <feather-icon size="18" icon="ChevronDownIcon" />
                </div>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="12" v-if="isClientFilter">
              <validation-provider
                #default="validationContext"
                name="Client"
                rules="required"
              >
                <b-form-group
                  class="required"
                  label="Client"
                  label-for="client_id"
                  label-cols-md="3"
                >
                  <div class="form-col-select">
                    <v-select
                      id="client_id"
                      v-model="documentData.client_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="clientOptions"
                      :clearable="true"
                      label="name_en"
                      :reduce="(option) => option.id"
                      placeholder="Select Client"
                      @input="
                        () => {
                          documentData.client_bank_id = null;
                        }
                      "
                    />

                    <feather-icon size="18" icon="ChevronDownIcon" />

                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="12" v-if="isClientFilter">
              <validation-provider
                #default="validationContext"
                name="Bank Account"
                rules="required"
              >
                <b-form-group
                  class="required"
                  label="Bank Account"
                  label-for="client_bank_id"
                  label-cols-md="3"
                >
                  <div class="form-col-select">
                    <v-select
                      id="client_bank_id"
                      v-model="documentData.client_bank_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="
                        clientBankOptions.filter(
                          (bank) => bank.client_id == documentData.client_id
                        )
                      "
                      :disabled="documentData.client_id === null ? true : false"
                      :clearable="true"
                      label="account_number"
                      :reduce="(option) => option.id"
                      placeholder="Select Bank"
                    >
                      <template #option="data">
                        {{ data.bank.name }} - {{ data.account_number }}
                      </template>
                      <template #selected-option="data">
                        {{ data.bank.name }} - {{ data.account_number }}
                      </template>
                    </v-select>
                    <feather-icon size="18" icon="ChevronDownIcon" />

                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- <b-col cols="12" md="12">
              <validation-provider
                #default="validationContext"
                name="file_name"
                rules="required"
              >
                <b-form-group
                  label="File Name"
                  label-for="file_name"
                  label-cols-md="3"
                  class="required"
                >
                  <b-form-input
                    id="file_name"
                    v-model="documentData.file_name"
                    :state="getValidationState(validationContext)"
                    placeholder="Enter file name"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col> -->

            <b-col cols="12" md="12">
              <validation-provider
                #default="validationContext"
                name="Authorised User"
                rules="required"
              >
                <b-form-group
                  label="Authorised User"
                  label-for="authorised_users"
                  label-cols-md="3"
                  class="required"
                >
                  <div class="form-col-select">
                    <v-select
                      id="authorised_users"
                      v-model="documentData.authorised_users"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="userOptions"
                      :clearable="false"
                      label="name"
                      :reduce="(option) => option.id"
                      multiple
                      placeholder="Select Staff"
                      @input="(val) => handleUserSelection(val)"
                    />
                    <feather-icon size="18" icon="ChevronDownIcon" />
                  </div>
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="12">
              <validation-provider
                #default="validationContext"
                name="Upload document"
                rules="required"
              >
                <b-form-group
                  label="Upload doc."
                  label-for="upload"
                  label-cols-md="3"
                  class="required"
                >
                  <div id="file-drag-drop">
                    <div class="fileform" ref="fileform">
                      <div class="mt-4">
                        <feather-icon
                          size="36"
                          class="color0"
                          icon="UploadCloudIcon"
                        />
                        <p class="color0">Select a file</p>
                        <p style="color: #bdbdbd">
                          File size no more than 10MB
                        </p>
                        <a
                          href="javascript:void(0)"
                          class="file-button"
                          @click="$refs.pickFile.$el.childNodes[0].click()"
                          >SELECT FILE</a
                        >

                        <b-form-file
                          v-model="documentData.image_files"
                          style="display: none"
                          @input="getImagePreviews"
                          ref="pickFile"
                          placeholder="Choose a file or drop it here..."
                          drop-placeholder="Drop file here..."
                          multiple
                        ></b-form-file>
                        <b-form-invalid-feedback
                          class="mt-1"
                          :state="getValidationState(validationContext)"
                        >
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </div>
                      <!-- <span class="drop-files">Drop the files here!</span> -->
                    </div>
                    <div class="d-flex flex-wrap">
                      <div
                        class="file-listing"
                        v-for="(files, index) in documentData.image_files"
                      >
                        <!-- <img class="preview mr-1" ref="preview" /> -->
                        <span class="text">{{ files.name }}</span>
                        <a class="remove ml-2" v-on:click="removeFile(index)">
                          <feather-icon size="18" class="" icon="Trash2Icon" />
                        </a>
                      </div>
                    </div>
                    <!-- <div
                      class="file-listing"
                      v-show="documentData.image_files && documentData.image_files.name"
                    >
                      <img class="preview mr-1" ref="preview" />
                      <span class="text">{{
                        documentData.image_files && documentData.image_files.name
                      }}</span>

                      <a class="remove ml-2" v-on:click="removeFile">
                        <feather-icon size="18" class="" icon="Trash2Icon" />
                      </a>
                    </div> -->
                  </div>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <div class="d-flex mt-2 justify-content-end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <feather-icon size="16" class="mr-1" icon="CheckCircleIcon" />
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </b-overlay>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard,
  BOverlay,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormCheckbox,
  BButton,
  BFormFile,
} from "bootstrap-vue";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import documentStoreModule from "../documentStoreModule";
import router from "@/router";
import store from "@/store";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BOverlay,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    BButton,
    BFormFile,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
  },
  data() {
    return {
      dragAndDropCapable: false,
      files: [],
    };
  },
  methods: {
    determineDragAndDropCapable() {
      var div = document.createElement("div");
      return (
        ("draggable" in div || ("ondragstart" in div && "ondrop" in div)) &&
        "FormData" in window &&
        "FileReader" in window
      );
    },
    getImagePreviews() {
      // check image_files is empty array
      // console.log('files',this.documentData.image_files)
      if (
        !Array.isArray(this.documentData.image_files) &&
        this.documentData.image_files
      ) {
        // check size > 10MB
        if (this.documentData.image_files.size >= 1024 * 1024 * 10) {
          this.$bvToast.toast(`Please drop a file size < 10 MB file!`, {
            title: "Error",
            autoHideDelay: 2500,
            variant: "danger",
          });
          this.documentData.image_files = [];
        } else {
          let file = this.documentData.image_files;
          console.log(file);
          if (
            file.type ==
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          ) {
            // word file ".docx"
            this.$refs.preview.src =
              process.env.VUE_APP_IMAGE_URL + "icon/word.png";
          } else if (
            file.type ==
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
            file.type == "application/vnd.ms-excel"
          ) {
            // excel file ".xlsx" and ".xls"
            this.$refs.preview.src =
              process.env.VUE_APP_IMAGE_URL + "icon/excel.png";
          } else if (file.type == "application/pdf") {
            // pdf file ".pdf"
            this.$refs.preview.src =
              process.env.VUE_APP_IMAGE_URL + "icon/pdf.png";
          } else if (
            file.type == "image/jpeg" ||
            file.type == "image/jpg" ||
            file.type == "image/png" ||
            file.type == "image/gif" ||
            file.type == "image/webp" ||
            file.type == "image/svg"
          ) {
            /*
              Create a new FileReader object
            */
            let reader = new FileReader();
            console.log(reader);

            /*
              Add an event listener for when the file has been loaded
              to update the src on the file preview.
            */
            reader.addEventListener(
              "load",
              function () {
                this.$refs.preview.src = reader.result;
              }.bind(this),
              false
            );

            /*
              Read the data for the file in through the reader. When it has
              been loaded, we listen to the event propagated and set the image
              src to what was loaded from the reader.
            */
            reader.readAsDataURL(this.documentData.image_files);
          } else if (
            file.type != "image/jpeg" &&
            file.type != "image/jpg" &&
            file.type != "image/png" &&
            file.type != "image/gif" &&
            file.type != "image/webp" &&
            file.type != "image/svg"
          ) {
            // files other than images
            this.$refs.preview.src =
              process.env.VUE_APP_IMAGE_URL + "icon/others.jpeg";
          }
        }
      }
    },
    removeFile(val) {
      console.log(val);
      console.log(this.documentData.image_files);
      if (Array.isArray(this.documentData.image_files)) {
        console.log("is array");
        this.documentData.image_files.splice(val, 1);
      } else {
        delete this.documentData.image_files[val];
        console.log("is not array.");
      }
    },
    handleUserSelection(val) {
      console.log(val);
      if (val.includes(0)) {
        this.documentData.authorised_users.length = 0;
        this.userOptions
          .filter((x) => x.id != 0)
          .forEach((user) => {
            return this.documentData.authorised_users.push(user.id);
          });
        this.documentData.all_bit = 1;
      } else {
        this.documentData.all_bit = 0;
      }
      // if (this.documentData.authorised_users.includes(0)) {
      //   this.documentData.authorised_users = [0];
      //   this.documentData.all_bit = 1;
      // } else {
      //   this.documentData.all_bit = 0;
      // }
      console.log(this.documentData.authorised_users);
    },
    hide() {
      if (this.isClientFilter) {
        this.$router.replace("/documents/client");
      } else {
        this.$router.replace("/documents/staff");
      }
    },
    onSubmit() {
      console.log(this.documentData);
      if (!this.documentData.image_files) {
        this.$swal({
          text: "Please uplaod the file",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          showClass: {
            popup: "animate__animated animate__bounceIn",
          },
          buttonsStyling: false,
        });
        return;
      }

      this.$swal({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonText: "Upload",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          let formData = new FormData();
          if (this.documentData.image_files) {
            this.documentData.image_files.forEach((file) => {
              formData.append("file[]", file);
            });
          }
          // only upload one file
          // formData.append("file", this.documentData.image_files);
          formData.append("is_client", this.isClientFilter);
          formData.append("all_bit", this.documentData.all_bit);
          formData.append("file_date", this.documentData.file_date);
          // formData.append("file_name", this.documentData.file_name);
          if (this.documentData.document_category_id)
            formData.append(
              "document_category_id",
              this.documentData.document_category_id
            );
          if (this.documentData.document_type_id)
            formData.append(
              "document_type_id",
              this.documentData.document_type_id
            );
          if (this.documentData.client_id)
            formData.append("client_id", this.documentData.client_id);
          if (this.documentData.client_bank_id)
            formData.append("client_bank_id", this.documentData.client_bank_id);
          if (this.documentData.bank_id)
            formData.append("bank_id", this.documentData.bank_id);
          if (this.documentData.authorised_users) {
            this.documentData.authorised_users.forEach((user) => {
              formData.append(
                "authorised_users[]",
                typeof user == "object" ? user.id : user
              );
            });
          }
          this.loading = true;
          store
            .dispatch("app-document/addDocument", formData)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              //this.documentData = response.data.documentData
              this.hide();
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    // handleFileName(value) {
    //   // console.log(isNaN(value));
    //   // console.log(this.documentData.document_category_id)
    //   if (value && this.documentData.document_category_id) {
    //     // console.log(this.documentData.file_date);
    //     let date_split = this.documentData.file_date.split("-");
    //     let yr = date_split[0];
    //     let monthIndex = date_split[1] - 1;
    //     let day = date_split[2];
    //     let date = new Date(yr, monthIndex, day);
    //     // console.log(date);
    //     this.documentData.file_name =
    //       date.getDate() +
    //       " " +
    //       this.months[date.getMonth()] +
    //       " - " +
    //       this.documentCategoryOptions.filter((item) =>
    //         item.id == value ? value : this.documentData.document_category_id
    //       )[0].category_name;
    //     // console.log(this.documentData.file_name)
    //   } else {
    //     this.documentData.file_name = "";
    //   }
    // },
  },
  mounted() {
    /*
      Determine if drag and drop functionality is capable in the browser
    */
    this.dragAndDropCapable = this.determineDragAndDropCapable();
    /*
      If drag and drop capable, then we continue to bind events to our elements.
    */
    if (this.dragAndDropCapable) {
      /*
       Listen to all of the drag events and bind an event listener to each
       for the fileform.
     */
      [
        "drag",
        "dragstart",
        "dragend",
        "dragover",
        "dragenter",
        "dragleave",
        "drop",
      ].forEach(
        function (evt) {
          /*
            For each event add an event listener that prevents the default action
            (opening the file in the browser) and stop the propagation of the event (so
            no other elements open the file in the browser)
        */
          this.$refs.fileform.addEventListener(
            evt,
            function (e) {
              e.preventDefault();
              e.stopPropagation();
            }.bind(this),
            false
          );
        }.bind(this)
      );

      /*
        Add an event listener for drop to the form
      */
      this.$refs.fileform.addEventListener(
        "drop",
        function (e) {
          /*
          Capture the files from the drop event and add them to our local files
          array.
        */
          // if (e.dataTransfer.files.length > 1) {
          //   this.$bvToast.toast(`Please drop only one file!`, {
          //     title: "Error",
          //     autoHideDelay: 2500,
          //     variant: "danger",
          //   });
          //   e.dataTransfer.files = [];
          //   this.documentData.image_files = [];
          // } else
          if (e.dataTransfer.files[0].size >= 1024 * 1024 * 10) {
            this.$bvToast.toast(`Please drop a file size < 10 MB file!`, {
              title: "Error",
              autoHideDelay: 2500,
              variant: "danger",
            });
            e.dataTransfer.files = [];
            this.documentData.image_files = [];
          } else {
            // image files = the dropped files
            // this.documentData.image_files = e.dataTransfer.files;
            // this.getImagePreviews();
          }

          //  for( let i = 0; i < e.dataTransfer.files.length; i++ ){
          //      this.documentData.image_files = e.dataTransfer.files[i];
          //     this.getImagePreviews();
          //    }
        }.bind(this)
      );
    }
  },
  watch: {
    $route(to, from) {
      if (to.name == "document-client-upload") {
        this.isClientFilter = 1;
      } else if (to.name == "document-staff-upload") {
        this.isClientFilter = 0;
      }
    },
    trigger(newVal, oldVal) {
      // this.authorised_users = this.documentData.authorised_users.map((user) => user.id);
      if (this.documentData.all_bit == 1) {
        // this.authorised_users = [0];
        this.userOptions
          .filter((x) => x.id != 0)
          .forEach((user) => {
            return this.documentData.authorised_users.push(user.id);
          });
        if (
          this.userOptions.length - 1 !=
          this.documentData.authorised_users.length
        ) {
          this.documentData.all_bit = 0;
        } else {
          this.documentData.all_bit = 1;
        }
      } else {
        this.authorised_users = this.documentData.authorised_users.map(
          (user) => user.id
        );
        this.documentData.all_bit = 0;
      }
    },
    // documentData:{
    //   handler(newItem,oldItem){
    //     console.log('file',newItem.image_files)
    //   },
    //   deep: true
    // }
  },
  setup() {
    const isClientFilter = ref(0);
    const DOCUMENT_APP_STORE_MODULE_NAME = "app-document";
    const documentData = ref({
      id: 0,
      file_date: new Date().toISOString().slice(0, 10),
      document_type_id: null,
      document_category_id: null,
      client_id: null,
      bank_id: null,
      image_files: [],
    });
    const loading = ref(false);

    const months = ref([]);
    months.value = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    if (!store.hasModule(DOCUMENT_APP_STORE_MODULE_NAME))
      store.registerModule(DOCUMENT_APP_STORE_MODULE_NAME, documentStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DOCUMENT_APP_STORE_MODULE_NAME))
        store.unregisterModule(DOCUMENT_APP_STORE_MODULE_NAME);
    });

    onMounted(() => {
      refetchOption();
      if (router.currentRoute.name == "document-client-upload") {
        isClientFilter.value = 1;
      } else {
        isClientFilter.value = 0;
      }
    });

    const userOptions = ref([]);
    const documentTypeOptions = ref([]);
    const documentCategoryOptions = ref([]);
    const bankOptions = ref([]);
    const clientOptions = ref([]);
    const clientBankOptions = ref([]);
    const refetchOption = () => {
      store
        .dispatch("app/fetchOptionList", {
          user: true,
          document_category: true,
          document_type: true,
          bank: true,
          client: true,
          client_bank: true,
        })
        .then((response) => {
          console.log(response);
          userOptions.value = response.data.users;
          documentCategoryOptions.value = response.data.document_categories;
          documentTypeOptions.value = response.data.document_types;
          bankOptions.value = response.data.banks;
          clientOptions.value = response.data.clients;
          clientBankOptions.value = response.data.client_banks;
          userOptions.value.unshift({ id: 0, name: "All" });
        });
    };

    const resetDocumentData = () => {
      documentData.value = JSON.parse(JSON.stringify({ id: 0 }));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetDocumentData);

    return {
      isClientFilter,
      userOptions,
      documentCategoryOptions,
      documentTypeOptions,
      bankOptions,
      refFormObserver,
      getValidationState,
      resetForm,
      loading,
      documentData,
      clientOptions,
      clientBankOptions,
      months,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@/assets/scss/variables/_variables.scss";

.authorised-user-button {
  padding: 7px 20px;
  cursor: pointer;
}

.fileform {
  // display: block;
  height: 250px;
  width: 100%;
  border: 1px solid #bdbdbd;
  text-align: center;
  border-radius: 5px;
}

div.file-listing {
  display: flex;
  margin: auto;
  padding: 10px;
  justify-content: center;
  align-items: center;
  align-items: center;
  align-items: center;
  align-items: center;
  align-items: center;

  img {
    height: 100px;
  }
}

.text {
  max-width: 250px;
  overflow-x: hidden;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
}

a.remove {
  color: #ff0000;

  &:hover {
    color: #ff0000;
  }

  // text-align: center;
}

a.file-button {
  display: inline-block;
  background: $primary;
  color: #fff;
  border-radius: 6px;
  padding: 0.75rem 2.5rem;
  border: 0px;

  &:hover {
    color: #fff;
  }
}
</style>
